import React, { useContext } from 'react';
import Image from 'gatsby-image';
import { Link, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { DiscussionEmbed } from 'disqus-react';
import { Layout, Bio, SEO } from '../components/Common/';
import { SetToggleContext } from '../providers/ToggleProvider';
import './post.scss';

export const query = graphql`
  query ($uid: String!) {
    prismicBlog (uid: { eq: $uid }) {
      uid
      data {
        date(formatString: "DD-MM-YYYY")
        title {
          text
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          url
          alt
        }
        content {
          html
          text
        }
      }
    }
  }
`;

const PostTemplate = ({ data: { prismicBlog: post } }) => {
  const { locale } = useIntl();
  const toggleSeoElement = useContext(SetToggleContext);
  const image = post.data.image.localFile.childImageSharp;
  const disqusConfig = {
    shortname: `${process.env.GATSBY_DISQUS_NAME}`,
    config: { identifier: post.uid, title: post.data.title.text },
  }
  const selectedLanguage = locale === 'en' ? 'en' : 'es';
  const urlPost = `https://holivielvaldez.dev/${selectedLanguage}/${post.uid}`;
  toggleSeoElement(false);

  return ( 
    <Layout>
      <SEO
        postTitle={post.data.title.text}
        postDescription={post.data.content.text}
        postUrl={urlPost}
        postDate={post.data.date}
        postImage={post.data.image.url}
        postAlt={post.data.image.alt}
      />
      <section className="pt-32 md:pt-48 pb-20 mx-auto w-full md:w-4/5">
        <article className="w-11/12 md:w-10/12 lg:w-4/6 mx-auto">
          <h1 className="text-5xl md:text-6xl font-bold text-center">{post.data.title.text}</h1>
          <p className="text-4xl text-center">{post.data.date}</p>
          { image.fluid ?
            <Image
              fluid={image.fluid}
              alt={post.title}
              className="mb-12 mt-6"
            />
              :
            <img className="mb-12 mt-6 w-full" src={post.data.image.url} alt={post.data.image.alt} />
          }
          <div className="post-content-body">
            <div dangerouslySetInnerHTML={{ __html: post.data.content.html }} /> 
          </div>
          <Link to={`${locale}/blog`} className="text-3xl my-4" >
            <strong>
              &larr; { locale === 'en' ? 'Back to all post' : 'Volver a todos los artículos' }
            </strong> 
          </Link>
          <Bio />
          <div className="mt-12 lg:mt-20">
            <DiscussionEmbed { ...disqusConfig } />
          </div>
        </article>
      </section>
    </Layout>
  );
}
 
export default PostTemplate;